/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-03-15",
    versionChannel: "nightly",
    buildDate: "2025-03-15T00:06:21.461Z",
    commitHash: "1ce97863c06843b3eb6ab43667a3e0dceb77278b",
};
